import { Injectable } from '@angular/core';
import { Title }     from '@angular/platform-browser';

@Injectable()
export class PageService {
	public constructor(public titleService: Title ) { }

	setTitle(title: string) {
		this.titleService.setTitle(title + (title!==""?" | ":"") + "");
	}
}
