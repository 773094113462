import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {environment} from '../../../environments/environment';
import { version } from '../../../environments/version';
import {animate, style, transition, trigger} from '@angular/animations';
import {UtilsService} from '../../services/utils.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
	animations: [
		trigger('EnterLeave', [
			transition(':enter', [
				style({opacity: '0'}),
				animate('250ms ease', style({opacity: '1'}))
			]),
		])
	]
})
export class LoginComponent implements OnInit, AfterViewInit {
	@ViewChild('loginInput', {static: true}) loginInput: ElementRef;
	@ViewChild('forgotInput', {static: true}) forgotInput: ElementRef;

    show_error = false;
    code_error: string = null;

    password: string = null;
    email: string = null;
    loading = false;
    version = null;
	forgotSuccess = false;
	showForgotFlag = false;
	showForgotEmailError = false;

	forgotData = {
		email: null,
	};
	forgotProgress = false;
	forgotResult = null;

    constructor(
    	private utils: UtilsService,
    	private userService: UserService
	) {
        this.userService.isLoggedIn.subscribe( (e) => {
            this.loading = false;
        });

        this.userService.loginErrorMessage.subscribe( (e) => {
            this.loading = false;
            this.show_error = e !== '';
            this.code_error = e;
        });
        this.version = version.version;
    }

    ngOnInit() {
    }


    login() {
		this.show_error = false
		this.code_error = null;
        this.loading = true;
        this.userService.login(this.email, this.password);
        this.email = null;
        this.password = null;
    }

	ngAfterViewInit() {
		setTimeout(() => {
			if (this.loginInput) {
				this.loginInput.nativeElement.focus();
			}
		}, 250);
	}


	showForgot() {
		this.forgotResult = false;
		this.forgotProgress = false;
		this.forgotSuccess = false;
		this.showForgotFlag = true;
		this.showForgotEmailError = false;
		setTimeout(() => {
			if (this.forgotInput) {
				this.forgotInput.nativeElement.focus();
			}
		}, 250);
	}

	hideForgot() {
		this.forgotResult = false;
		this.forgotProgress = false;
		this.forgotSuccess = false;
		this.showForgotFlag = false;
		this.showForgotEmailError = false;
		setTimeout(() => {
			if (this.loginInput) {
				this.loginInput.nativeElement.focus();
			}
		}, 250);
	}

	forgot() {
		this.showForgotEmailError = false;
		this.forgotResult = false;

		if (this.utils.isUndefinedOrNull(this.forgotData.email) || (this.forgotData.email.trim() === '')) {
			this.showForgotEmailError = true;
		} else if (!this.utils.validateEmail(this.forgotData.email)) {
			this.showForgotEmailError = true;
		} else {

			this.forgotProgress = true;

			this.userService.forgot(this.forgotData).subscribe(
				(r: any) => {
					this.forgotProgress = false;

					if (!r.success) {
						this.forgotResult = r.error._e || 'Error inesperado al procesar su solicitud';
						this.showForgotEmailError = false;
						this.forgotData.email = null;
					} else {
						this.forgotProgress = false;
						this.forgotResult = false;
						this.forgotSuccess = true;
					}
				},
				(r: any) => {
					this.forgotProgress = false;
					this.forgotResult = r.error._e || 'Error inesperado al procesar su solicitud';
				}
			);
		}
	}

}
