import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
    selector: '[ngenter]'
})
export class NgenterDirective {

    @Output() ngenter = new EventEmitter();

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        if (event.keyCode === 13) {
            this.ngenter.emit();
            event.preventDefault();
        }
    }
}
