<div class="container" id="main-app-container">
	<div class="row">
		<div class="col-12">
			<h1 class="main-title">Modificar foto</h1>
		</div>
	</div>

	<div class="row">
		<div class="col-12">
			<input type="file" accept=".jpg,.jpeg,.png" #filectr style="display: none" (change)="fileChangeEvent($event)"/>

			<div id="avatarplaceholder" (click)="doSelect()" *ngIf="!imageloaded">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
					<path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"></path>
				</svg>
			</div>

			<div *ngIf="!imageloaded" class="buttons-flex mt-3">
				<a href="javascript:void(0)" (click)="doSelect()" class="btn btn-confirm"><span class="fa fa-camera"></span> Seleccionar</a>
				<a href="javascript:void(0)" (click)="close()" class="btn btn-close"><span class="fa fa-times"></span> Cancelar</a>
			</div>

			<image-cropper
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
				[aspectRatio]="1"
				format="jpeg"
				cropperMinHeight="150"
				cropperMinWidth="150"
				(imageCropped)="imageCropped($event)"
				(imageLoaded)="imageLoaded()"
				(cropperReady)="cropperReady()"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>

			<div *ngIf="error !== ''">
				<div class="alert alert-sm alert-danger my-3"><span class="fa fa-warning"></span> {{error}}</div>
				<div class="text-center"><a href="javascript:void(0)" (click)="doSelect()">seleccionar otra imagen</a></div>
			</div>

			<ng-container *ngIf="imageloaded">
				<div class="alert" *ngIf="loading">
					<app-loading></app-loading>
				</div>

				<div class="buttons-flex mt-3" *ngIf="!loading">
					<a *ngIf="!loading" class="btn btn-confirm" (click)="confirm()"><span class="fa fa-check"></span> Confirmar</a>
					<a *ngIf="!loading" class="btn btn-close" (click)="close()"><span class="fa fa-times"></span> Cancelar</a>
				</div>
			</ng-container>
		</div>
	</div>
</div>

