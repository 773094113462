<div class="container px-md-0" id="resetpwdmain">
    <div class="row">
        <div class="col-12 col-md-4 offset-md-4 px-md-0">
            <div class="welcome-box">
				<div class="logo-app"><img src="/img/logo.png" alt=""></div>

                <h1 class="text-center mb-3">Reestablecer contraseña</h1>

                <div class="form-group" *ngIf="!(resetSuccess)">
                    <div class="form-group">
                        <input type="password" placeholder="Nueva contraseña" class="form-control" #passInput maxlength="255" name="password" [(ngModel)]="resetData.password" (ngModelChange)="checkForm()"/>
                        <div class="invalid-feedback" *ngIf="resetErrors.password">{{resetErrors.password}}</div>
                    </div>

                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Confirme su nueva contraseña" maxlength="255" name="password1" [(ngModel)]="resetData.password_confirmation" (ngModelChange)="checkForm()"/>
                        <div class="invalid-feedback" *ngIf="resetErrors.password_confirmation">{{resetErrors.password_confirmation}}</div>
                    </div>
                </div>

                <div *ngIf="(resetProgress)">
                    <app-loading></app-loading>
                </div>

				<div *ngIf="resetResult" class="alert"><span class="fa fa-times-circle"></span> {{resetResult}}</div>

                <ng-container *ngIf="(resetSuccess)">
                    <div class="alert alertsuccess text-center">
                        <span class="fa fa-check reset-success"></span>
                        <br/>
                        Su contraseña ha sido modificada
                    </div>
                    <div class="text-center mt-4"><a href="javascript:void(0)" routerLink="/login" replaceUrl="true"><span class="fa fa-arrow-left"></span> Volver al inicio</a></div>
                </ng-container>

                <ng-container *ngIf="!(resetSuccess)">
                    <div class="text-center"><button *ngIf="!(resetProgress)" type="submit" class="btn btn-primary btn-lg mt-4" (click)="reset()">Enviar</button></div>
                    <div class="text-center mt-4"><a href="javascript:void(0)" routerLink="/login" replaceUrl="true"><span class="fa fa-arrow-left"></span> Volver al inicio</a></div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
