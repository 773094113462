<div class="logo-responsive-sm">
	<div class="logo-responsive-sm-logo"><img id="logo-full" src="/img/logo-app.png"/><img id="logo-square" src="/img/logo-square.png"/></div>
	<div class="logo-responsive-sm-name">{{user.name}}</div>
	<div class="logo-responsive-sm-options dropdown"><a href="javascript:void(0)" (click)="doShowdrop()"><img *ngIf="user" [src]="user.foto"/></a><span title="Estado de conexión" class="onlinestatus" [ngClass]="{'online':isOnline}"></span></div>
	<div id="responsive-dropdown" class="dropdown-menu dropdown-menu-right text-right" [ngClass]="{'show': showdrop}">
		<a href="javascript:void(0)" (click)="hidedrop()" routerLink="/avatar" class="dropdown-item"><span class="fa fa-camera"></span>&nbsp;&nbsp;Modificar foto</a>
		<a href="javascript:void(0)" (click)="hidedrop()" routerLink="/changepwd" class="dropdown-item"><span class="fa fa-cogs"></span>&nbsp;&nbsp;Modificar contraseña</a>
		<a href="javascript:void(0)" (click)="hidedrop(); logout()" class="dropdown-item"><span class="fa fa-sign-out"></span>&nbsp;&nbsp;Cerrar sesión</a>
	</div>
</div>
