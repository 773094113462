<div class="" id="main-app-tabs">
	<div class="row">
		<div class="col-12 col-md-6 offset-md-3 app-tabs px-0">
			<a class="d-inline-block w-50 text-center" [ngClass]="{'active': activeView === 'F'}" (click)="activeView = 'F'" href="javascript:void(0)">Fichar</a>
			<a class="d-inline-block w-50 text-center" [ngClass]="{'active': activeView === 'H'}" (click)="activeView = 'H';" href="javascript:void(0)">Historial</a>
		</div>
	</div>
</div>

<div class="container" id="main-app-container">
    <div class="row" *ngIf="activeView === 'F'">

	</div>
    <div class="row" *ngIf="activeView === 'H'">

	</div>
</div>


