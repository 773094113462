<div class="container px-md-0">
	<div class="row">
		<div class="col-12 col-md-4 offset-md-4 px-md-0">
			<div class="welcome-box" *ngIf="!showForgotFlag" @EnterLeave>
				<div class="logo-app"><img src="/img/logo.png" alt=""></div>

				<h1 class="text-center mb-3">INGRESAR</h1>

				<div class="form-group">
					<input type="text" placeholder="E-mail" name="dni" #loginInput class="form-control" [(ngModel)]="email" (ngenter)="login()" maxlength="100"/>
				</div>

				<div class="form-group">
					<input type="password" placeholder="Contraseña" name="password" class="form-control" [(ngModel)]="password" (ngenter)="login()" maxlength="100"/>
				</div>

				<div class="alert alert-danger" *ngIf="show_error">{{code_error}}</div>

				<div class="text-center">
					<app-loading *ngIf="loading"></app-loading>

					<button type="submit" class="btn btn-primary" (click)="login()" *ngIf="!loading">Ingresar</button>

					<div class="text-center mt-4"><a href="javascript:void(0)" (click)="showForgot()">Olvidó su contraseña?</a></div>
				</div>
			</div>


			<div class="welcome-box" *ngIf="showForgotFlag" id="forgotMain" @EnterLeave>
				<form>
					<div class="logo-app"><img src="/img/logo.png" alt=""></div>

					<h1 text-center mb-3>Reestablecer contraseña</h1>

					<div class="form-group" *ngIf="!forgotSuccess">
						<input type="email" placeholder="Correo electrónico" class="form-control" id="forgot-email" #forgotInput name="forgot-email" [(ngModel)]="forgotData.email"/>
						<div class="invalid-feedback d-block mb-3" *ngIf="showForgotEmailError">Ingrese un correo electrónico válido</div>
					</div>

					<div *ngIf="forgotProgress" class="text-center">
						<app-loading></app-loading>
					</div>

					<div *ngIf="forgotResult" class="alert alert-danger">{{forgotResult}}</div>

					<ng-container *ngIf="forgotSuccess">
						<div class="alert alertsuccess text-center">
							<span class="fa fa-check forgot-success"></span>
							<br/>
							Hemos enviado un correo electrónico a <strong>{{forgotData.email}}</strong> con las instrucciones para reestablecer su contraseña
						</div>
						<div class="text-center mt-4"><a href="javascript:void(0)" (click)="hideForgot()"><span class="fa fa-arrow-left"></span> Volver al inicio</a></div>
					</ng-container>

					<ng-container *ngIf="!forgotSuccess">
						<div class="text-center">
							<button *ngIf="!forgotProgress" type="submit" class="btn btn-primary" (click)="forgot()">Enviar</button>
						</div>
						<div class="text-center mt-4"><a href="javascript:void(0)" (click)="hideForgot()"><span class="fa fa-arrow-left"></span> Volver al inicio</a></div>
					</ng-container>
				</form>
			</div>
		</div>

	</div>
</div>

