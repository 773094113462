<app-responsivelogo *ngIf="isLoggedIn"></app-responsivelogo>

<div id="maincontainer">

	<div id="maincontainer-content" [ngClass]="{'justify-content-center': !isLoggedIn}">
		<div id="maincontainer-outlet-content">
			<router-outlet></router-outlet>
		</div>

		<div class="footer-version" *ngIf="isLoggedIn">Ver.: {{version}}</div>
	</div>
</div>

