import {ErrorHandler, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

	constructor(
		public http: HttpClient,
	) {
	}

	handleError(error) {
		if (!environment.production) {
			// if we're on DEV, do not report to server
			console.error(error);
			return;
		}

		const userData = sessionStorage.getItem('errorHandlerUserData');
		let msg = '';

		if (error.message) {
			msg += error.message;
		}
		if (error.stack) {
			msg += '\n\n' + error.stack;
		}

		if (msg === '') {
			error.userData = userData;
			error.location = window.location.href;
			msg = error;
		}
		else {
			if (userData) {
				msg = 'User: ' + userData + '\nLocation: ' + window.location.href + '\n\n' + msg;
			}
		}

		try {
			this.http
				.post(environment.server_path + environment.appApi + 'reportError', {data: msg}).subscribe(
				r => {
				},
				e => {
				}
			);
		} catch (dummy) {
		}
	}
}
