import {Component, OnInit} from '@angular/core';
import {IUserData, UserService} from '../services/user.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
	selector: 'app-fichaje',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	loading = false;
	user: IUserData;
	activeView: string = 'F';

	constructor(
		private router: Router,
		private toastr: ToastrService,
		private userService: UserService
	) {
		this.userService.userData.subscribe(ud => {
			this.user = ud;

			if (this.user && this.user.pwdchgreq) {
				this.router.navigate(['/changepwd']);
			}
		});
	}

	ngOnInit() {
	}
}
