import {Injectable} from '@angular/core';


export class IdLabel {
    id: any;
    l: string;

    public constructor(id: any, l: string) {
        this.id = id;
        this.l = l;
    }
}

//Temp class to hold server's response (backward comp)
export class IdLabelWithGroup {
    id: any;
    l: string;
    g: string;

    public constructor(id: any, l: string, g: string) {
        this.id = id;
        this.l = l;
        this.g = g;
    }
}

export class IdLabelGroup {
    l: string;
    g: IdLabel[] = [];

    public constructor(l: string) {
        this.l = l;
    }
}


@Injectable()
export class UtilsService {
	validateEmail(email: string): boolean {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	validatePasswordLength(pass: string): boolean {
		return pass.length >= 6;
	}

	validatePasswordChars(pass: string): boolean {
		const re = /([a-zA-Z0-9\.\_\-\$\^\!\~\@\#\%\\\/\&\*\(\)\,\;\:\'\"\[\]\{\}\+\|]+)$/;
		return re.test(pass);
	}


    isUndefined(o) {
        return typeof o === 'undefined';
    }

    isNumber(value: any): boolean {
        return !isNaN(parseInt(value, 10));
    }

    padNumberLeft2(value: number) {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return '';
        }
    }


    //Strips time from YYYYMMDDTHH:mm:ss datetime
    stripTimeFromDate(d: any) {
        if (this.isUndefinedOrNull(d)) {
            return '';
        }
        if (typeof d == 'object')	//should be date
        {
            return '' + d.getFullYear() + '-' + (this.padNumberLeft2(d.getMonth() + 1)) + '-' + this.padNumberLeft2(d.getDate());
        } else if (d.length > 8) {
            return d.substr(0, 8);
        } else {
            return d;
        }
    }

    isUndefinedOrNull(o) {
        return this.isUndefined(o) || (o === null);
    }


    add_hours(h1: string, h2: string) {
        let parts1 = h1.split(':');
        let parts2 = h2.split(':');
        if ((parts1.length < 2) || (parts2.length < 2)) {
            return false;
        }

        let toth = parseInt(parts1[0]) + parseInt(parts2[0]);
        let totm = parseInt(parts1[1]) + parseInt(parts2[1]);
        if (totm >= 60) {
            toth += Math.trunc(totm / 60);
            totm = totm % 60;
        }

        return ('00' + toth).slice(-2) + ':' + ('00' + totm).slice(-2);
    }


    /**
     * Converts object to array, adds "_key_" property to each entry in order to keep track of the original key, if tracking key is not needed, just use Object.values()
     */
    object_to_array(o: any) {
        if (this.isUndefinedOrNull(o)) {
            return [];
        }
        let res = [];
        let k = Object.keys(o);
        k.forEach((key) => {
            let n = {};
            if (typeof o[key] === 'object') {
                n = o[key];
            } else {
                n['_l_'] = o[key];
            }

            if (n === null) {
                n = {};
            }	//if the prop is null, then n becomes null and can't assign params

            n[key] = o[key];
            n['_key_'] = key;

            res.push(n);
        });
        return res;
    }


    /**
     * Converts object to array, adds "_key_" property to each entry in order to keep track of the original key, if tracking key is not needed, just use Object.values()
     * This func does not adds the original object n[key] = o[key]; if o[key] is an object
     */
    object_to_array_no_circref(o: any) {
        if (this.isUndefinedOrNull(o)) {
            return [];
        }
        let res = [];
        let k = Object.keys(o);
        k.forEach((key) => {
            let n = {};
            if (typeof o[key] === 'object') {
                n = o[key];
            } else {
                n['_l_'] = o[key];
            }

            if (n === null) {
                n = {};
            }	//if the prop is null, then n becomes null and can't assign params

            if (typeof o[key] !== 'object') {
                n[key] = o[key];
            }	//
            n['_key_'] = key;

            res.push(n);
        });
        return res;
    }


    /**
     * Maps objects with {id, l, g} to a grouped array {g, items[]} to be used with (eg) report filter multi group
     */
    group_array(arr: any[]) {
        let tmp: any[] = [];
        let maps: any[] = [];
        arr.forEach((o) => {
            if (typeof maps[o.g] === 'undefined') {
                maps[o.g] = tmp.length;	//index of tmp which is length-1 or length before pushing the actual obj
                tmp.push({g: o.g, items: []});
            }

            tmp[maps[o.g]].items.push(o);
        });

        return tmp;
    }


    /**
     * Filters an array of objects where all have the "id" property, excluding the object which id = exclude_id
     */
    filter_arr(thearray: Array<any>, exclude_id) {
        return this.filter_arr_on(thearray, exclude_id, 'id');
    }

    /**
     * Filters an array of objects where all have the "exclude_prop" property, excluding the object which prop = exclude_id
     */
    filter_arr_on(thearray: Array<any>, exclude_id, exclude_prop) {
        let res = [];
        thearray.forEach((v) => {
            if (v[exclude_prop] && (v[exclude_prop] != exclude_id)) {
                res.push(v);
            }
        });
        return res;
    }
}
