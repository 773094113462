import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from './not-found-component/not-found-component.component';
import {LoginComponent} from './account/login/login.component';
import {AuthGuard} from './services/authguard.service';
import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AvatarComponent} from './account/avatar/avatar.component';
import {ChangepwdComponent} from './account/changepwd/changepwd.component';
import {ResetpwdComponent} from './account/resetpwd/resetpwd.component';


const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: AppComponent,
    },	//default route
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        component: DashboardComponent,
    },
    {
        path: 'avatar',
        canActivate: [AuthGuard],
        component: AvatarComponent,
    },
    {
        path: 'changepwd',
        canActivate: [AuthGuard],
        component: ChangepwdComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'resetpwd',
        component: ResetpwdComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    }	//not found
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
