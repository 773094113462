import {Component, OnInit, Renderer2} from '@angular/core';
import {UserService} from './services/user.service';
import {version} from "../environments/version";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	isLoggedIn = false;
	version: string = null;
	displaySidebar: any;

	constructor(private userService: UserService,
		private renderer: Renderer2,
	) {
		this.userService.isLoggedIn.subscribe((e) => {
			// if logs in, redirect to home
			if (e) {
				// launch 'share position'
				// this.userService.getCurrentPosition();

				this.renderer.addClass(document.body, 'loggedin');
				this.isLoggedIn = true;
			} else {
				this.renderer.removeClass(document.body, 'loggedin');
				this.isLoggedIn = false;
			}

			this.version = version.version;
		});

		// try to pull the user information
		this.userService.pullme();
	}

	ngOnInit() {

		if ((typeof window.navigator.onLine !== 'undefined') && (window.navigator.onLine === false)) {
			this.userService.setOnlineStatus(false);
		} else {
			this.userService.setOnlineStatus(true);
		}

		window.addEventListener('offline', (e) => {
			this.userService.setOnlineStatus(false);
		});

		window.addEventListener('online', (e) => {
			this.userService.setOnlineStatus(true);
		});
	}
}
