import {HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, EMPTY, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {

    constructor(
        public router: Router,
		private toastr: ToastrService,
        public userService: UserService,
    ) {
    }

	private transformReply(err: HttpErrorResponse) {
		if ( typeof err.error.success === 'undefined') {
			if (err.error.errors) {
				err.error.success = false;
				let msg = '';
				if (err.error.errors instanceof Object) {
					Object.keys(err.error.errors).forEach((k) => {
						msg +=  err.error.errors[k] + '\n';
					});
				}
				err.error._e = msg;
			}
		}
		return err;
	}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token = this.userService.g_token;
        const userId = this.userService.g_userId;

        if (token && userId) {
            request = request.clone({
                setHeaders: {
					Authorization: 'Bearer ' + token,
                }
            });
        }

        return next.handle(request).pipe(
            catchError(
                (err) => {

					if (err.status === 500) {
						// show toast
						this.toastr.error('Se ha producido un error al intentar procesar su solicitud', 'Error', {
							progressBar: true,
							progressAnimation: 'increasing',
							timeOut: 5000,
							positionClass: 'toast-bottom-center'
						});
						return throwError(this.transformReply(err));

					}
					else if (err instanceof HttpErrorResponse) {
                        if ((err.status === 401) || (err.status === 422)) {
							if (!this.router.url.startsWith('/login') && !this.router.url.startsWith('/resetpwd')) {
								if (err.status === 401) {
									this.userService.clearTokens();
									this.userService.navigateHome();
									return EMPTY;
								}
								else {
									return throwError(this.transformReply(err));
								}
							}
							else {
								return throwError(this.transformReply(err));
							}
                        } else {
							return throwError(this.transformReply(err));
                        }
                        return EMPTY;
                    }

					return throwError(this.transformReply(err));
                }
            )
        );
    }
}
