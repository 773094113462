import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {IntPasswordResetData, UserService} from '../../services/user.service';
import {UtilsService} from '../../services/utils.service';

@Component({
    selector: 'app-resetpwd',
    templateUrl: './resetpwd.component.html',
    styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('passInput', {static: true}) passInput: ElementRef;

    resetData: IntPasswordResetData;

    formSubmitted = false;

    resetErrors = {
        password: '',
        password_confirmation: ''
    };

    resetResult:any = false;
    resetProgress = false;
    resetSuccess = false;

    routeSub: Subscription = null;

    constructor(
        private utils: UtilsService,
        private route: ActivatedRoute,
        private user: UserService,
        private router: Router
    ) {
        this.resetData = {
            email: '',
            password: '',
            password_confirmation: '',
            token: ''
        };
    }

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.resetData.email = this.route.snapshot.queryParams.email;
                this.resetData.token = this.route.snapshot.queryParams.token;
            }
        });

        this.resetData.email = this.route.snapshot.queryParams.email;
        this.resetData.token = this.route.snapshot.queryParams.token;
    }

    ngOnDestroy(): void {
        if (this.routeSub !== null) {
            this.routeSub.unsubscribe();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.passInput) {
                this.passInput.nativeElement.focus();
            }
        }, 250);
    }

    reset() {
        this.formSubmitted = true;

        if (this.checkForm()) {
            return;
        } else {
            const rd: IntPasswordResetData = {
                email: this.resetData.email,
                password: this.resetData.password,
                password_confirmation: this.resetData.password_confirmation,
                token: this.resetData.token
            };

            this.resetResult = false;
            this.resetProgress = true;
            this.resetSuccess = false;

			this.user.forgotSetPassword(this.resetData).subscribe(
				(r: any) => {
					this.resetProgress = false;

					if (!r.success) {
						this.resetResult = r.error._e || 'Error inesperado';
					} else {
						this.resetSuccess = true;
					}
				},
				(r: any) => {
					this.resetProgress = false;
					this.resetResult = r.error._e || 'Error inesperado';
				}
			);
        }
    }

    checkForm() {
        if (!this.formSubmitted) {
            return false;
        }

        this.resetErrors = {
            password: '',
            password_confirmation: ''
        };

        let errors = false;

        if (this.utils.isUndefinedOrNull(this.resetData.password) || (this.resetData.password.trim() === '')) {
            errors = true;
            this.resetErrors.password = 'Ingrese su nueva contraseña';
        } else if (!this.utils.validatePasswordLength(this.resetData.password)) {
            errors = true;
            this.resetErrors.password = 'Ingrese al menos 6 letras, números o caracteres especiales';
        } else if (!this.utils.validatePasswordChars(this.resetData.password)) {
            errors = true;
            this.resetErrors.password = 'Ingrese solo letras, números o caracteres';
        } else if (this.resetData.password !== this.resetData.password_confirmation) {
            errors = true;
            this.resetErrors.password_confirmation = 'La nueva contraseña y su confirmación no coinciden';
        }

        return errors;
    }

}
