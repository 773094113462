import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {IUserData, UserService} from '../services/user.service';

@Component({
    selector: 'app-responsivelogo',
    templateUrl: './responsivelogo.component.html',
    styleUrls: ['./responsivelogo.component.scss']
})
export class ResponsivelogoComponent implements OnInit, AfterViewInit {

	displaySidebar: boolean = false;
	@Output() onToggleSidebar = new EventEmitter<boolean>()

    showdrop = false;
    showdropRequested = false;
    viewInit = false;
    user: IUserData;
    isOnline: boolean;

    @HostListener('window:click', ['$event'])
    hideshowdrop(e) {
        if (!this.viewInit) return;
        if (!this.showdropRequested) {
            this.showdrop = false;
        }
        this.showdropRequested = false;
    }

    @Output() onClose = new EventEmitter();

    constructor(private userService: UserService) {
        this.userService.userData.subscribe(ud => {
            this.user = ud;
        });
        this.userService.onlineStatus.subscribe(st => {
            this.isOnline = st;
        });
    }

    ngOnInit() {
    }

    close() {
        this.onClose.emit();
    }

    doShowdrop() {
        this.showdropRequested = true;
        this.showdrop = !this.showdrop;
    }

    hidedrop() {
        this.showdropRequested = true;
        this.showdrop = false;
    }

    logout() {
        this.userService.logout();
    }

    ngAfterViewInit(): void {
        this.viewInit = true;
    }

	toggleSidebar() {
		this.displaySidebar = !this.displaySidebar;
		this.onToggleSidebar.emit(this.displaySidebar);
	}
}
