import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ToastrService} from 'ngx-toastr';

export interface IntGenericSuccessErrorReply {
	success: boolean;

	// the _e key is a global error message, then other keys are view specific
	error?: any;

	payload?: any;
}

export interface IntPasswordResetData {
	email: string;
	password: string;
	password_confirmation: string;
	token: string;
}

export interface IntPasswordChangeData {
	password: string;
	password_confirmation: string;
	actual: string;
}

export interface IUserData {
    id: string;
    name: string;
	email: string;
	foto: string;
	rol: string;
	pwdchgreq: boolean;
}


@Injectable()
export class UserService {
    public user: IUserData;

    public g_userId: string|null;
    public g_token: string|null;

    public infoPulled = false;
    public isPullingInfo = false;
    public loggedIn = false;
    public isLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);		// use ReplaySubject insead of BehaviorSubject to avoid emitting the 1st value which is not the real state
    public userData: BehaviorSubject<IUserData> = new BehaviorSubject(null);
    public loginErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');
    public onlineStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public coordenadas: string = null;

    constructor(
        public http: HttpClient,
        private toastr: ToastrService,
        public router: Router
    ) {
        this.g_userId = localStorage.getItem('userId');
        this.g_token = localStorage.getItem('token');
    }

    clearMessages() {
        this.loginErrorMessage.next('');
    }

    setOnlineStatus(st: boolean) {
        this.onlineStatus.next(st);
    }

    forcepullme() {
		this.infoPulled = false;
		this.pullme();
	}

	/**
	 * Resets user.pwdchgreq to avoid calling pullme again
	 */
	resetPwdchgreqFlag() {
    	this.user.pwdchgreq = false;
		this.userData.next(this.user);
	}

    pullme() {
		if (this.isPullingInfo) {
			// already a call in progress, silently return in order to avoid race conditions
			return;
		}

        if (this.infoPulled) {
			this.isLoggedIn.next(this.loggedIn);
        } else {
			this.isPullingInfo = true;
            this.http.get(environment.server_path + environment.appApi + 'appme').subscribe((ret: any) => {
                if (ret.success && ret.payload) {
                	ret.payload.foto = ret.payload.foto || '/img/avatar.png';
                    localStorage.setItem('userId', ret.payload.id);
                    this.g_userId = ret.payload.id;

                    this.infoPulled = true;

                    this.loggedIn = true;
                    this.isLoggedIn.next(true);

                    this.user = ret.payload;
                    this.userData.next(ret.payload);

                    // set user info for error handler (we don't delete it after logout so we know which user was logged in)
                    sessionStorage.setItem('errorHandlerUserData', ret.payload.id + ' / ' + ret.payload.name);

                    if (ret.payload.pwdchgreq) {
                    	this.router.navigate(['/changepwd']);
					}
                    else if ((this.router.url === '/') || (this.router.url === '/login')) {
                    	this.navigateHome();
					}
                } else {
					this.loggedIn = false;
					this.isLoggedIn.next(false);
					this.user = null;
					this.userData.next(null);
					this.clearTokens();

					if ((this.router.url === '/') && !this.router.url.startsWith('/resetpwd')) {
						this.navigateHome();
					}
                }

				// remove pulling flag
				this.isPullingInfo = false;

            }, (err) => {
				this.loggedIn = false;
				this.isLoggedIn.next(false);
				this.user = null;
				this.userData.next(null);
				this.clearTokens();

				// remove pulling flag
				this.isPullingInfo = false;

				if ((this.router.url === '/') && !this.router.url.startsWith('/resetpwd')) {
					this.navigateHome();
				}
            });
        }
    }

    login(user: string, pass: string): any {
        const login = {email: user, password: pass};

        if ((user === null) || (pass === null) || (user.trim() === '') || (pass.trim() === '')) {
			this.loginErrorMessage.next('Ingrese su e-mail y contraseña');
			return;
		}

        // clears existing token
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.g_userId = null;
        this.g_token = null;

        this.http.post(environment.server_path + environment.appApi + 'applogin', login).subscribe((ret: any) => {
            if (ret.success && ret.payload) {
                localStorage.setItem('token', ret.payload.token);
                localStorage.setItem('userId', ret.payload.id);
				ret.payload.foto = ret.payload.foto || '/img/avatar.png';

                this.g_userId = ret.payload.id;
                this.g_token = ret.payload.token;

                this.pullme();
            } else {
            	if (ret.msg) {
					this.loginErrorMessage.next(ret.msg);
				}
            	else if (ret.error && ret.error._e) {
					this.loginErrorMessage.next(ret.error._e);
				}
            }
        }, (err) => {
			if (err.msg) {
				this.loginErrorMessage.next(err.msg);
			}
			else if (err.error && err.error._e) {
				this.loginErrorMessage.next(err.error._e);
			}
        });
    }

    clearTokens() {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        this.g_userId = null;
        this.g_token = null;

        this.infoPulled = false;

        this.loginErrorMessage.next('');

        if (this.loggedIn !== false) {
        	// avoid emitting if not changed
			this.loggedIn = false;
			this.isLoggedIn.next(false);
		}

        this.user = null;
        this.userData.next(null);
    }

    logout() {
        this.http.post(environment.server_path + environment.appApi + 'logout', {}).subscribe((r) => {
            this.clearTokens();
			this.navigateHome();
        }, (err) => {
            this.clearTokens();
			this.navigateHome();
        });
    }


    navigateHome() {
		// pasamos replaceUrl para que el estado del history sea el actual, de esta manera evitamos que se pueda volver atrás
        if (!this.loggedIn) {
            this.router.navigate(['/login'], { replaceUrl: true });
        } else {
            this.router.navigate(['/dashboard'], { replaceUrl: true });
        }
    }

    getCurrentPosition() {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition( (p) => this.setCurrentPosition(p));
        }
    }

    setCurrentPosition(position) {
        this.coordenadas = position.coords.latitude + ',' + position.coords.longitude;
    }

	public uploadAvatar(avatar: string): Observable<IntGenericSuccessErrorReply> {
		return this.http
			.post<IntGenericSuccessErrorReply>(environment.server_path + environment.appApi + 'avatar', avatar);
	}

	public changePassword(pass: IntPasswordChangeData): Observable<IntGenericSuccessErrorReply> {
		return this.http
			.post<IntGenericSuccessErrorReply>(environment.server_path + environment.appApi + 'changepass', pass);

	}

	public forgot(credentials: any): Observable<IntGenericSuccessErrorReply> {
		return this.http
			.post<IntGenericSuccessErrorReply>(environment.server_path + environment.appApi + 'forgot', credentials);

	}

	public forgotSetPassword(credentials: IntPasswordResetData): Observable<IntGenericSuccessErrorReply> {
		return this.http
			.post<IntGenericSuccessErrorReply>(environment.server_path + environment.appApi + 'reset', credentials);

	}

	addPushSubscriber(sub: PushSubscription) {
		return this.http
			.post<IntGenericSuccessErrorReply>(environment.server_path + environment.appApi + 'push', sub);
	}
}
