<div class="container">

	<div class="row">
		<div class="col-12">
			<h1 class="main-title">Modificar contraseña</h1>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-md-4 offset-md-4">

			<div class="alert alert-warning" *ngIf="user && user.pwdchgreq">
				<span class="fa fa-exclamation-triangle mb-2"></span>
				<br/>
				Debe modificar su contraseña actual
			</div>

			<div class="form-group mt-3">
				<label>Contraseña actual</label>
				<input type="password" [(ngModel)]="data.actual" maxlength="100" class="form-control">
			</div>

			<div class="form-group">
				<label>Nueva contraseña</label>
				<input type="password" [(ngModel)]="data.password" maxlength="100" class="form-control">
			</div>

			<div class="form-group">
				<label>Confirme su nueva contraseña</label>
				<input type="password" [(ngModel)]="data.password_confirmation" maxlength="100" class="form-control">
			</div>

			<div class="alert alert-danger text-center" *ngIf="error">{{error}}</div>

			<div class="alert" *ngIf="loading">
				<app-loading></app-loading>
			</div>

			<div class="buttons-flex" *ngIf="!loading">
				<a href="javascript:void(0)" class="btn btn-confirm" (click)="confirm()">Modificar</a>
				<a href="javascript:void(0)" *ngIf="user && !user.pwdchgreq" class="btn btn-close" (click)="close()">Cancelar</a>
			</div>
		</div>
	</div>
</div>
