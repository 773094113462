import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {IntGenericSuccessErrorReply, UserService} from '../../services/user.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, AfterViewInit {
    @ViewChild('filectr') filectr;

    loading = false;
    imageloaded = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    error = '';


    constructor(
        public userService: UserService,
        public router: Router
    ) {
		this.userService.userData.subscribe(ud => {
			if (ud && ud.pwdchgreq) {
				this.router.navigate(['/changepwd']);
			}
		});
    }

    doSelect() {
    	this.error = '';
        this.filectr.nativeElement.click();
    }

    ngOnInit(): void {
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
        this.imageloaded = true;
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    confirm() {
        this.loading = true;

        this.userService.uploadAvatar(this.croppedImage).subscribe(
            (r: IntGenericSuccessErrorReply) => {
                this.loading = false;
                if (!r.success) {
                    this.error = r.error._e || 'Error inesperado';
                } else {
                	this.userService.forcepullme();
                    this.router.navigate(['/fichaje']);
                }
            },
            (r: any) => {
                this.loading = false;
                this.error = r.error._e || 'Error inesperado';
            }
        );
    }

    close() {
		this.router.navigate(['/fichaje']);
    }

    ngAfterViewInit(): void {
        this.doSelect();
    }
}
