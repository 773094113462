import {Component, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IntGenericSuccessErrorReply, IntPasswordChangeData, IUserData, UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {UtilsService} from '../../services/utils.service';


@Component({
	selector: 'app-changepwd',
	templateUrl: './changepwd.component.html',
	styleUrls: ['./changepwd.component.scss']
})
export class ChangepwdComponent implements OnInit {

	data = {
		actual: '',
		password: '',
		password_confirmation: ''
	};

	error = '';
	loading = false;
	user: IUserData;

	constructor(
		public toastrService: ToastrService,
		public userService: UserService,
		public router: Router,
		private utils: UtilsService
	) {
		this.userService.userData.subscribe(ud => {
			this.user = ud;
		});
	}

	ngOnInit() {
	}

	close() {
		this.router.navigate(['/fichaje']);
	}

	confirm() {
		this.error = '';
		if (this.utils.isUndefinedOrNull(this.data.actual) || (this.data.actual.trim() === '')) {
			this.error = 'Ingrese su contraseña actual';
			return;
		} else if (this.utils.isUndefinedOrNull(this.data.password) || (this.data.password.trim() === '')) {
			this.error = 'Ingrese su nueva contraseña';
			return;
		} else if (!this.utils.validatePasswordLength(this.data.password)) {
			this.error = 'Ingrese al menos 6 letras, números o caracteres especiales';
			return;
		} else if (!this.utils.validatePasswordChars(this.data.password)) {
			this.error = 'Ingrese solo letras, números o caracteres';
			return;
		} else if (this.data.password !== this.data.password_confirmation) {
			this.error = 'La nueva contraseña y su confirmación no coinciden';
			return;
		}

		const pass: IntPasswordChangeData = {
			actual: this.data.actual,
			password: this.data.password,
			password_confirmation: this.data.password_confirmation,
		};


		this.loading = true;

		this.userService.changePassword(pass).subscribe(
			(r: IntGenericSuccessErrorReply) => {
				this.loading = false;
				if (!r.success) {
					this.error = r.error._e || 'Error inesperado';
				} else {
					this.toastrService.success('Su contraseña ha sido modificada', '', {
						timeOut: 4000,
						enableHtml: true,
						positionClass: 'toast-bottom-center'
					});
					this.userService.resetPwdchgreqFlag();
					this.router.navigate(['/fichaje']);
				}
			},
			(r: any) => {
				this.loading = false;
				this.error = r.error._e || 'Error inesperado';
			}
		);
	}

}
