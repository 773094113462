import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {NotFoundComponent} from './not-found-component/not-found-component.component';
import { LoginComponent } from './account/login/login.component';
import {HttpInterceptor} from './services/http.interceptor';
import {UserService} from './services/user.service';
import {PageService} from './services/page.service';
import {UtilsService} from './services/utils.service';
import {AuthGuard} from './services/authguard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import {ResponsivelogoComponent} from './responsivelogo/responsivelogo.component';
import { ToastrModule } from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_BASE_HREF} from '@angular/common';
import {NgenterDirective} from './services/ngenter.directive';
import {LoadingComponent} from './components/loading/loading.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AvatarComponent} from './account/avatar/avatar.component';
import {ChangepwdComponent} from './account/changepwd/changepwd.component';
import {ResetpwdComponent} from './account/resetpwd/resetpwd.component';
import {TextMaskModule} from 'angular2-text-mask';
import {ErrorHandlerService} from './services/error-handler.service';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateESParserFormatter} from './services/ngdatepicker.formatter';
import {NgbDateISOStringAdapter} from './services/ngdatepicker.string.adapter';

@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
		LoadingComponent,
        LoginComponent,
        DashboardComponent,
		AvatarComponent,
		ChangepwdComponent,
        ResponsivelogoComponent,
        ResetpwdComponent,
        NgenterDirective,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
        FormsModule,
		TextMaskModule,
        AppRoutingModule,
		ImageCropperModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        NgbDatepickerModule
    ],
    providers: [
        UserService,
        PageService,
        UtilsService,
        AuthGuard,
		NgbDateESParserFormatter,
		{provide: NgbDateAdapter, useClass: NgbDateISOStringAdapter},
		{provide: NgbDateParserFormatter, useClass: NgbDateESParserFormatter},
        {provide: APP_BASE_HREF, useValue: environment.baseHref },
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
		{provide: ErrorHandler, useClass: ErrorHandlerService}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
